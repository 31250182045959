<template>
    <div>
        <voucher-add 
            :is-voucher-form-sidebar-active.sync="isVoucherFormSidebarActive"
          
            @refetch-data="addItemData" 
        />
        <course-add 
            :is-course-add-sidebar-active.sync="isCourseAddSidebarActive"
          
            @refetch-data="addItemData" 
        />
        <course-num 
            :is-course-num-sidebar-active.sync="isCourseNumSidebarActive"
          
            @refetch-data="addItemData" 
        />
        
        <course-set-add
        :is-course-set-add-sidebar-active.sync="isCourseSetAddSidebarActive"
        @refetch-data="addItemData" 
        />
        <voucher-used 
            :is-voucher-used-sidebar-active.sync="isVoucherUsedSidebarActive"
            :vouchers="payment.vouchers"
            :item-data="itemData"
            @refetch-data="updateCreditVoucher" 
        />
        <product-add 
            :is-product-add-sidebar-active.sync="isProductAddSidebarActive"
           
            @refetch-data="addItemData" 
        />
        <check-list-form :is-check-list-form-sidebar-active.sync="isCheckListFormSidebarActive"
           
           @refetch-data="addItemData"/>
        <gateway-form 
            :is-gateway-form-active.sync="isGatewayFormActive"
            :price="payment.total"
            :title="modal_title"
            @refetch-data="updatePayment"
        />
        <discount-item 
        :is-discount-item-sidebar-active.sync="isDiscountItemSidebarActive"
        :item-data="itemDiscount"
        @refetch-data="updateItems" 
        />
       
        <b-card no-body>
            <div class="m-2 p-1">
                <span v-if="opdcard_code">{{  opdcard_code }}</span>
                {{ patient.first_name }} {{ patient.last_name }}
            </div>
        </b-card>
        <b-card no-body>
            <div class="m-2 p-1">
                <b-row>
                    <b-col
                        cols="12"
                        md="4"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    ></b-col>
                    <b-col
                        cols="12"
                        md="8"
                    >
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button  
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="ml-1"
                                @click="isCheckListFormSidebarActive = true"
                                variant="info">
                                <feather-icon icon="PlusIcon" />
                                เพิ่มรายการตรวจ
                            </b-button>
                            <b-button  
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="ml-1"
                                @click="isProductAddSidebarActive = true"
                                variant="info">
                                <feather-icon icon="PlusIcon" />
                                เพิ่ม ยา/ผลิตภัณฑ์
                            </b-button>
                            <b-button  
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="ml-1"
                                @click="isCourseSetAddSidebarActive = true"
                                variant="info">
                                <feather-icon icon="PlusIcon" />
                                ซื้อคอร์ส
                            </b-button>
                            <b-button  
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="ml-1"
                                @click="isCourseNumSidebarActive = true"
                                variant="info">
                                <feather-icon icon="PlusIcon" />
                                ตามจำนวน
                            </b-button>
                            <b-button  
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="ml-1"
                                @click="isCourseAddSidebarActive = true"
                                variant="info">
                                <feather-icon icon="PlusIcon" />
                                รายครั้ง
                            </b-button>
                            
                            <b-button  
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                class="ml-1"
                                @click="isVoucherFormSidebarActive = true"
                                variant="info">
                                <feather-icon icon="PlusIcon" />
                                เพิ่ม Voucher
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="m-2 p-1 border-viva">
                <b-table
                    ref="refitemsListTable"
                   
                    responsive
                    :fields="tableColumns"
                
                    :items="items"
                    show-empty
                    empty-text="No matching records found"
                   
                    class="position-relative"
                >
                <template #cell(no)="data">
                    
                    {{  data.index+1 }}
                </template>
                <template #cell(item_name)="data">
                    
                    {{  data.item.item_name }}<br />
                    <small v-if="data.item.description" v-html="data.item.description"></small>
                </template>
                <template #cell(discount)="data">
                    
                    {{  data.item.discount }}
                   
                </template>

                
                <template #cell(price)="data">
                    <span v-if="data.item.types == 'voucher'">
                        {{  getCommaDecimal(data.item.credits+'.00') }}

                    </span>
                    <span v-else>
                        {{  getCommaDecimal(data.item.price) }}
                    </span>
                  
                </template>
                <template #cell(action)="data">
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                   
                    
                    @click="updatediscountItem(data.item)"
                    >
                    <custom-icon  style="width:18px; height:18px;" class="text-success cursor" name="discount" size="12" />
                    </b-button>
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-danger"
                   
                    
                    @click="DeleteItem(data.index,data.item.item_name)"
                    >
                    <feather-icon icon="Trash2Icon" class="text-danger "  />
                    </b-button>
                   
                   
                </template>
                <template #cell(qty)="data">
                      <b-form-spinbutton
                        id="sb-inline"
                        v-model="data.item.qty"
                        min="1"
                        inline
                        @input="updateItem(data.item)"
                      />
                </template>
                <template #cell(total)="data">
                    
                    {{  getCommaDecimal(data.item.total.toFixed(2)) }}
                </template>
                <template #cell(voucher)="data">
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                   
                    
                    @click="addVoucherUsed(data.item)"
                    >
                        <custom-icon  style="width:14px; height:14px;" name="voucher" size="12" />
                  
                    </b-button>
                 
                </template>
                </b-table>

            </div>
            <div class="m-2 p-1 border-viva text-black form-blue">
                <b-row>
                    <b-col md="7">
                        <div v-for="voucher in payment.vouchers" :key="'voucher-cat-'+voucher.category_id">
                            <b-row class="mt-1">
                                <b-col cols="6" md="6">
                                    ใช้ {{ voucher.category_name}}
                                </b-col>
                                <b-col cols="6" md="2" class="text-right text-danger"  >
                                    <b>{{ getCommaDecimal(voucher.used.toFixed(2)) }} บาท</b>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="6" md="6">
                                    {{ voucher.category_name}} คงเหลือ
                                </b-col>
                                <b-col cols="6" md="2" class="text-right text-success"  >
                                <b v-if="voucher.balance > 0"> {{ getCommaDecimal(voucher.balance.toFixed(2)) }} บาท</b>    
                                <b v-else> 0.00 บาท</b>    
                                
                                </b-col>     

                            </b-row>

                        </div>
                        
                        
                        <b-row class="mt-1">
                            <b-col cols="6" md="6">
                               มูลค่ารวม
                            </b-col>
                            <b-col cols="6" md="2" class="text-right "  >
                               <b> {{ getCommaDecimal(payment.total_items.toFixed(2)) }} บาท</b>
                            </b-col>     

                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols="4" md="2">
                                ส่วนลด
                            </b-col>
                            <b-col cols="6" md="4"  >
                                <div class="grid-half">
                                    <b-form-group
                                   
                                        label="สูงสุด 100%"
                                        label-for="discount_value"
                                    >
                                        <b-form-input
                                            id="discount_value"
                                            v-model="payment.discount_value"
                                            
                                            @input="calPayment"
                                            
                                            
                                            
                                        />

                                            
                                    </b-form-group>
                                    <b-form-group
                                        label="ประเภทส่วนลด"
                                        label-for="discount_type"
                                        
                                    >
                                    <b-form-select  v-model="payment.discount_type" :options="discount_options" @input="calPayment"></b-form-select>
                                        

                                            
                                    </b-form-group>
                                </div>
                            </b-col>     

                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols="6" md="6">
                               มูลค่ารวมหลังส่วนลด
                            </b-col>
                            <b-col cols="6" md="2" class="text-right"  >
                                <b> {{ getCommaDecimal(payment.price_total.toFixed(2)) }} บาท</b>
                            </b-col>     

                        </b-row>
                        <b-row class="mt-1">
                            <b-col sm="4" md="2">
                                ประเภทภาษี
                            </b-col>
                            <b-col sm="6" md="4"  >
                                <b-form-group
                                        label="เลือกประเภทภาษี"
                                        label-for="tax_type"
                                        
                                    >
                                    <b-form-select  v-model="payment.tax_type" :options="tax_options" @input="calPayment"></b-form-select>
                                        

                                            
                                    </b-form-group>
                            </b-col>     

                        </b-row>
                        <b-row class="mt-1">
                            <b-col sm="6" md="6">
                               ภาษีมูลค่าเพิ่ม
                            </b-col>
                            <b-col sm="6" md="2" class="text-right"  >
                                <b>{{ getCommaDecimal(payment.tax_value.toFixed(2)) }} บาท</b>
                            </b-col>     

                        </b-row>
                    </b-col>
                    <b-col md="5">
                        <div class="p-1 background-receipt">
                            <b-row>
                                <b-col sm="6">
                                มูลค่ารวม
                                </b-col>
                                <b-col sm="6"  class="text-right"  >
                                   <b>{{ getCommaDecimal(payment.total_items.toFixed(2)) }} บาท</b>
                                </b-col>    
                                 

                            </b-row>
                            <b-row class="mt-1">
                                <b-col sm="6">
                                    รวมยอดที่ต้องชำระ
                                </b-col>
                                <b-col sm="6"  class="text-right"  >
                                    <b class="text-danger">{{ getCommaDecimal(payment.total.toFixed(2)) }} บาท</b>
                                </b-col>
                                <b-col md="12" class="mt-5">
                                    <b-button block variant="outline-success" :disabled="items.length === 0" @click="onSubmit(1)">บันทึกฉบับร่าง</b-button>
                                 </b-col>
                                 <b-col md="12" class="mt-2">
                                    <b-button block variant="warning" :disabled="items.length === 0" @click="openPopup(2)">วางมัดจำ</b-button>
                                 </b-col>
                                 <b-col md="12" class="mt-2">
                                    <b-button block variant="info" :disabled="items.length === 0" @click="openPopup(3)">ชำระค่าบริการ</b-button>
                                 </b-col>
 
                            </b-row>
                        </div>
                    </b-col>
                </b-row>

            </div>
        </b-card>
       
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,BFormSpinbutton
} from 'bootstrap-vue'
import store from '@/store';
import { ref,onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import useReceiptForm from './useReceiptForm';
import receiptStoreModule from '../receiptStoreModule'
import voucherStoreModule from '../../voucher/voucherStoreModule'
import roomDetailStoreModule from '../../room/room-detail/roomDetailStoreModule'
import VoucherAdd from './VoucherAdd.vue'
import CourseAdd from './CourseAdd.vue'
import CourseNum from './CourseNum.vue'
import VoucherUsed from './VoucherUsed.vue'
import CheckListForm from './CheckListForm.vue'
import GatewayForm from '../gateway-form/GatewayForm.vue'
import { getCommaDecimal } from '@core/utils/filter'
import ProductAdd from './ProductAdd.vue';
import CourseSetAdd from './CourseSetAdd.vue'
import CustomIcon from '@/@core/components/feather-icon/CustomIcon.vue'
import DiscountItem from './DiscountItem.vue';
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,BFormSpinbutton,
    VoucherAdd,
    CourseAdd,
    CourseNum,
    VoucherUsed,
    GatewayForm,
    ProductAdd,
    CustomIcon,
    CourseSetAdd,
    DiscountItem,
    CheckListForm

    },
    directives:{
      //  'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            getCommaDecimal
        }
    },
    setup() {
        const RECEIPT_STORE_MODULE_NAME = 'app-receipt';
            if (!store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.registerModule(RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
            onUnmounted(() => {
                if (store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_STORE_MODULE_NAME)
            });
            const VOUCHER_STORE_MODULE_NAME = 'app-voucher';
            if (!store.hasModule(VOUCHER_STORE_MODULE_NAME)) store.registerModule(VOUCHER_STORE_MODULE_NAME, voucherStoreModule)
            onUnmounted(() => {
                if (store.hasModule(VOUCHER_STORE_MODULE_NAME)) store.unregisterModule(VOUCHER_STORE_MODULE_NAME)
            }); 
            const OPD_STORE_MODULE_NAME = 'app-roomdetail';
            if (!store.hasModule(OPD_STORE_MODULE_NAME)) store.registerModule(OPD_STORE_MODULE_NAME, roomDetailStoreModule)
            onUnmounted(() => {
                if (store.hasModule(OPD_STORE_MODULE_NAME)) store.unregisterModule(OPD_STORE_MODULE_NAME)
            });   
            //app-voucher
        const {
            patient,
            refitemsListTable,
            tableColumns,
            items,
            addItems,
            refetchData,
            updateCredit,
            isVoucherFormSidebarActive,
            payment,
            updateItems,
            removeItem,
            discount_options,
            calPayment,
            tax_options,
            isVoucherUsedSidebarActive,
            isCourseAddSidebarActive,
            isCourseNumSidebarActive,
            addVoucherUsed,
            itemData,
            onSubmit,
            openPopup,
            opdcard_code,
            isGatewayFormActive,
            modal_title,
            updatePayment,
            isProductAddSidebarActive,
            isCourseSetAddSidebarActive,
            isDiscountItemSidebarActive,
            updatediscountItem,
            itemDiscount,
            isCheckListFormSidebarActive,
            addItemData,
            updateItem,
            updateCreditVoucher
            
            } = useReceiptForm();

            return {
                patient,
                refitemsListTable,
                tableColumns,
                items,
                addItems,
                refetchData,
                isVoucherFormSidebarActive,
                isCourseAddSidebarActive,
                isCourseNumSidebarActive,
                payment,
                updateItems,
                removeItem,
                discount_options,
                calPayment,
                tax_options,
                isVoucherUsedSidebarActive,
                updateCredit,
                addVoucherUsed,
                itemData,
                onSubmit,
                openPopup,
                opdcard_code,
                isGatewayFormActive,
                modal_title,
                updatePayment,
                isProductAddSidebarActive,
                isCourseSetAddSidebarActive,
                isDiscountItemSidebarActive,
                updatediscountItem,
                itemDiscount,
                isCheckListFormSidebarActive,
                addItemData,
                updateItem,
                updateCreditVoucher
                
            }
        
    },
    methods:{
        DeleteItem(index,title){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Item :'+title, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                if(value === true){
                    this.removeItem(index);

               
                }
            
                //this.deleteData(id)
                })
        }
    }
}
</script>